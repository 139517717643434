<template>
	<div class="block-inner">
		<RichEditor v-model="block.content" :inline="true" :block-id="block.id" :paste-tags="false" placeholder="Write a question" @input="$emit('edit')" />
	</div>
</template>

<script>
	import RichEditor from '../RichEditor';

	export default {
		components: {
			RichEditor
		},
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		created() {
			if(!this.block.meta.maxFiles) {
				this.$set(this.block.meta, 'maxFiles', 1);
			}

			if(!this.block.meta.allowedFileTypes) {
				this.$set(this.block.meta, 'allowedFileTypes', ['*']);
			}
		}
	};
</script>